import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Row, Col, Badge } from 'antd';
import * as utils from '../utils';
import gzhImg from '../../images/gzh_hz.jpg';

class Footer extends React.Component<{
  location: {
    pathname: string;
  };
}> {
  handleLangChange = () => {
    const {
      location: { pathname },
    } = this.props;
    const currentProtocol = `${window.location.protocol}//`;
    const currentHref = window.location.href.substr(currentProtocol.length);

    if (utils.isLocalStorageNameSupported()) {
      localStorage.setItem('locale', utils.isZhCN(pathname) ? 'en-US' : 'zh-CN');
    }

    window.location.href =
      currentProtocol +
      currentHref.replace(
        window.location.pathname,
        utils.getLocalizedPathname(pathname, !utils.isZhCN(pathname)),
      );
  };

  render() {
    return (
      <footer id="footer">
        <div className="footer-wrap myFooter" >
          <div>
             <span className="swhz">商务合作QQ： 584042976、1126505489、563284570</span>
             <span className="swhz">邮箱：1126505489@qq.com</span>
             <span>开源技术交流群：XXX</span>
          </div>
          <div className="gzh">
               <div>关注ANGRY ANT公众号,了解更多动态</div>
               <div className="gzh"><img src={gzhImg} width="200"/></div>
          </div>
        </div>
        <div className="bottom-bar">
            Copyright © 2021~2022 广州好掌信息科技有限公司 版权所有.  <a href="https://beian.miit.gov.cn/" target="_blank">粤ICP备20020781号-1</a>
        </div>
      </footer>
    );
  }
}

export default Footer;
